import _kindOf from "kind-of";
import _isNumber from "is-number";
var exports = {};
var typeOf = _kindOf;
var isNumber = _isNumber;

exports = function hasValue(val) {
  // is-number checks for NaN and other edge cases
  if (isNumber(val)) {
    return true;
  }

  switch (typeOf(val)) {
    case "null":
    case "boolean":
    case "function":
      return true;

    case "string":
    case "arguments":
      return val.length !== 0;

    case "error":
      return val.message !== "";

    case "array":
      var len = val.length;

      if (len === 0) {
        return false;
      }

      for (var i = 0; i < len; i++) {
        if (hasValue(val[i])) {
          return true;
        }
      }

      return false;

    case "file":
    case "map":
    case "set":
      return val.size !== 0;

    case "object":
      var keys = Object.keys(val);

      if (keys.length === 0) {
        return false;
      }

      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];

        if (hasValue(val[key])) {
          return true;
        }
      }

      return false;

    default:
      {
        return false;
      }
  }
};

export default exports;